import { ApplicantLeaseNegotiationStartDatePage } from "src/components/applicants/ApplicantLeaseNegotiationStartDatePage";
import { ApplicantLeaseStartDatePage } from "src/components/applicants/ApplicantLeaseStartDatePage";
import { useIsNegotiated } from "src/hooks/applicants/useIsNegotiated";
import { useLeaseNegotiationEnabled } from "src/hooks/applicants/useLeaseNegotiationEnabled";
import { useRequireNegotiation } from "src/hooks/applicants/useRequireNegotiation";
import { Error } from "../../components/Error";
import { ApplicantLandingCard } from "../../components/applicants/ApplicantLandingCard";
import { ApplicantOneSteps } from "../../components/applicants/ApplicantOneSteps";
import { ApplicantPortalProvider } from "../../components/applicants/ApplicantPortalProvider";
import { useAuth } from "../../contexts/auth";
import { useApplicantAnalytics } from "../../hooks/applicants/useApplicantAnalytics";
import { useFetchedApplicant } from "../../hooks/applicants/useFetchedApplicant";
import { useIsPrimaryApplicant } from "../../hooks/applicants/useIsPrimaryApplicant";

function Content() {
  const applicantAnalytics = useApplicantAnalytics();
  const { user, loading } = useAuth();
  const applicantNode = useFetchedApplicant();
  const requireLeaseStartDateInput = useIsPrimaryApplicant();
  const requireNegotiation = useRequireNegotiation();
  const isNegotiated = useIsNegotiated();
  const leaseNegotiationEnabled = useLeaseNegotiationEnabled();
  const isLogin = !!user;

  if (loading) {
    return null;
  } else if (requireLeaseStartDateInput && !isNegotiated) {
    // Lease Negotiation if enabled, else show Add move-in date page
    if (leaseNegotiationEnabled && requireNegotiation) {
      return <ApplicantLeaseNegotiationStartDatePage />;
    }
    return <ApplicantLeaseStartDatePage />;
  } else if (!isLogin) {
    return <ApplicantLandingCard />;
  } else if (user && applicantNode.doorsteadUser.doorsteadUserId !== user!.doorsteadUserId) {
    applicantAnalytics?.track("TP_APPLICANT_UNMATCHED");
    // If not login, show the onboarding card
    return <Error title="Sorry, this application doesn't belong to you." errorType="incorrectUser" />;
  } else {
    return <ApplicantOneSteps />;
  }
}

export default function ApplicationOneRoute() {
  return (
    <ApplicantPortalProvider>
      <Content />
    </ApplicantPortalProvider>
  );
}
